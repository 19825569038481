import { defineStore } from "pinia";
import { reactive } from "vue";
import { ToasterInterface } from "@/components/toaster/types/ToasterInterface";

export const useToasterStore = defineStore("toaster-store", () => {
  const toasterList = reactive({
    value: [] as ToasterInterface[],
  });

  function addToast(item: ToasterInterface) {
    const element = Object.assign({}, item);

    if (element.lifeTime) {
      element.lifeTime = element.lifeTime * 1000;
    } else {
      element.lifeTime = 5000;
    }
    element.id = Date.now().toString(36) + Math.random().toString(36).substr(2);

    setTimeout(() => {
      toasterList.value.unshift(element);
    }, 100);
  }

  function removeToast(id: string) {
    const objWithIdIndex = toasterList.value.findIndex((obj) => obj.id === id);
    if (objWithIdIndex > -1) {
      toasterList.value.splice(objWithIdIndex, 1);
    }
  }

  return { toasterList, addToast, removeToast };
});
