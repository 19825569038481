import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/scss/styles.scss";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { PiniaSharedState } from "pinia-shared-state";
import { useAuthStore } from "./store/authStore";
import { authenticate } from "./services/api/authentication/login";
import i18n from "@/plugins/i18n";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(PiniaSharedState({ enable: true, initialize: true, type: "native" }));

const app = createApp(App);

app.use(pinia).use(i18n).use(router).mount("#app");

const authStore = useAuthStore();

router.beforeEach((to, from, next) => {
  function is_authenticated() {
    if (to.matched.some((route) => route.meta.requiresAuth)) {
      return next();
    }
    if (to.matched.some((route) => route.meta.guestOnly)) {
      if (to.name === "QuestionDetailGuest") {
        return next({
          name: "QuestionDetailUser",
          params: to.params,
        });
      }
      if (to.name === "SearchQuestionGuest") {
        return next({
          name: "SearchQuestionUser",
          query: to.query,
        });
      }
      if (to.name === "ContactUsGuest") {
        return next({
          name: "ContactUsUser",
        });
      }
      if (to.name === "PrivacyPolicyGuest") {
        return next({
          name: "PrivacyPolicyUser",
        });
      }
      return next({
        name: "LandingUser",
        params: to.params,
        query: to.query,
      });
    }
  }

  function unauthenticated() {
    if (to.matched.some((route) => route.meta.guestOnly)) {
      return next();
    }
    if (to.matched.some((route) => route.meta.requiresAuth)) {
      return next({
        name: "LandingGuest",
      });
    }
  }

  if (authStore.isAuthorized.value) {
    authenticate()
      .then(() => {
        is_authenticated();
      })
      .catch(() => {
        authStore.isAuthorized.value = false;
        unauthenticated();
      });
  } else {
    unauthenticated();
  }
});
