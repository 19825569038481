import { _axios } from "@/services/api";

const { axiosClient } = _axios();
const path = "auth/";

export const login = (username: string, password: string) => {
  return axiosClient.post(`${path}login`, {
    username,
    password,
  });
};

export const logout = () => {
  return axiosClient.get(`${path}logout`);
};

export const authenticate = () => {
  return axiosClient.get(`${path}authenticate`);
};

export const registerUser = (
  username: string,
  email: string,
  password: string,
  response_token: string
) => {
  return axiosClient.post(`${path}register`, {
    username,
    email,
    password,
    token: response_token,
  });
};
