import { RouteRecordRaw } from "vue-router";

// Authenticated User Pages
const UserLayout = () => import("@/layout_user/UserLayout.vue");

const LandingUser = () => import("@/pages_user/LandingPageUser.vue");

const CreateQuestion = () => import("@/pages_user/CreateQuestion.vue");
const UserProfile = () => import("@/pages_user/settings/UserSettings.vue");
const PrivacyPolicyUser = () => import("@/pages_user/PrivacyPolicyUser.vue");
const QuestionDetailUser = () => import("@/pages_user/QuestionDetailUser.vue");
const ContactUsUser = () => import("@/pages_user/ContactUsUser.vue");

const MyQuestions = () => import("@/pages_user/MyQuestions.vue");
const SearchQuestionUser = () => import("@/pages_user/SearchQuestionUser.vue");

// Guest User Pages
const GuestLayout = () => import("@/layout_guest/GuestLayout.vue");

const LandingGuest = () => import("@/pages_guest/LandingPageGuest.vue");

const QuestionDetailGuest = () =>
  import("@/pages_guest/QuestionDetailGuest.vue");
const PrivacyPolicyGuest = () => import("@/pages_guest/PrivacyPolicyGuest.vue");
const ContactUsGuest = () => import("@/pages_guest/ContactUsGuest.vue");

const SearchQuestionGuest = () =>
  import("@/pages_guest/SearchQuestionGuest.vue");

// Router
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "GuestLayout",
    meta: {
      guestOnly: true,
    },
    redirect: "/LandingGuest",
    component: GuestLayout,
    children: [
      {
        path: "/:page?",
        name: "LandingGuest",
        component: LandingGuest,
        meta: {
          title: "JużPytam",
        },
      },
      {
        path: "/question-detail/:question_uuid/",
        name: "QuestionDetailGuest",
        component: QuestionDetailGuest,
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicyGuest",
        component: PrivacyPolicyGuest,
        meta: {
          title: "Privacy policy",
        },
      },
      {
        path: "/contact-us",
        name: "ContactUsGuest",
        component: ContactUsGuest,
        meta: {
          title: "Contact us",
        },
      },
      {
        path: "/search-question",
        name: "SearchQuestionGuest",
        component: SearchQuestionGuest,
        meta: {
          title: "Search page",
        },
      },
    ],
  },
  {
    path: "/",
    name: "UserLayout",
    meta: {
      requiresAuth: true,
    },
    redirect: "/LandingUser",
    component: UserLayout,
    children: [
      {
        path: "/:page?",
        name: "LandingUser",
        component: LandingUser,
        meta: {
          title: "JużPytam",
        },
      },
      {
        path: "/user-profile/",
        name: "UserProfile",
        component: UserProfile,
        meta: {
          title: "User profile",
        },
      },
      {
        path: "/create-question",
        name: "CreateQuestion",
        component: CreateQuestion,
        meta: {
          title: "Create question",
        },
      },
      {
        path: "/question-detail/:question_uuid/",
        name: "QuestionDetailUser",
        component: QuestionDetailUser,
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicyUser",
        component: PrivacyPolicyUser,
        meta: {
          title: "Privacy policy",
        },
      },
      {
        path: "/contact-us",
        name: "ContactUsUser",
        component: ContactUsUser,
        meta: {
          title: "Contact us",
        },
      },
      {
        path: "/my-questions/:page?",
        name: "MyQuestions",
        component: MyQuestions,
        meta: {
          title: "My questions",
        },
      },
      {
        path: "/search-question",
        name: "SearchQuestionUser",
        component: SearchQuestionUser,
        meta: {
          title: "Search page",
        },
      },
    ],
  },
];

export default routes;
