import { defineStore } from "pinia";
import { reactive } from "vue";

export const useAuthStore = defineStore(
  "auth-store",
  () => {
    const isAuthorized = reactive({
      value: false,
    });

    const username = reactive({
      value: "",
    });

    const email = reactive({
      value: "",
    });

    return { isAuthorized, username, email };
  },
  {
    persist: true,
  }
);
